import ThemisSurvey from "@/components/shared/survey"
import { mapGetters, mapActions } from "vuex"

export default {
  name      : "EmailVerified",
  components: { ThemisSurvey },
  data() {
    return {
      showSurvey      : false,
      surveySent      : false,
      isReturnToReport: false
    }
  },
  computed: {
    ...mapGetters({
      isSurveyAdded : "survey/isSurveyAdded",
      isAddingSurvey: "survey/isAddingSurvey"
    }),
    isMobileView() {
      return this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    ...mapActions({
      addSurvey: "survey/addSurvey"
    }),
    handleReturnToReport() {
      this.isReturnToReport = true
      this.showSurvey       = true
    },
    logout(surveyResponses) {
      if (!this.surveySent && surveyResponses) {
        this.addSurvey({ surveyResponses, isAuthPublic: false })
      }
      this.showSurvey = false
      this.surveySent = false

      if (this.isReturnToReport) {
        this.$router.push({
          name  : "messages",
          params: {
            locale : this.$route.params.locale,
            channel: this.$route.params.channel
          }
        })
      } else {
        this.$router.push({
          name  : "logout",
          params: {
            locale : this.$route.params.locale,
            channel: this.$route.params.channel
          }
        })
      }
    },
    submitSurveyResponse(surveyResponses) {
      this.addSurvey({ surveyResponses, isAuthPublic: false })
      this.surveySent = true
    }
  }
}